import { Slot } from "@radix-ui/react-slot";
import * as LabelPrimitive from "@radix-ui/react-label";
import { cva, VariantProps } from "class-variance-authority";
import {
	Controller,
	ControllerProps,
	FieldPath,
	FieldValues,
	useFormContext,
} from "react-hook-form";
import {
	useContext,
	createContext,
	type ElementRef,
	forwardRef,
	type HTMLAttributes,
	useId,
	type ComponentPropsWithoutRef,
} from "react";

import { cn } from "@/lib/utils";
import { Label } from "@/components/ui/label";

type FormFieldContextValue<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
	name: TName;
};

const FormFieldContext = createContext<FormFieldContextValue>({} as FormFieldContextValue);

const FormField = <
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
	props: ControllerProps<TFieldValues, TName>,
) => {
	return (
		<FormFieldContext.Provider value={{ name: props.name }}>
			<Controller {...props} />
		</FormFieldContext.Provider>
	);
};

const useFormField = () => {
	const fieldContext = useContext(FormFieldContext);
	const itemContext = useContext(FormItemContext);
	const { getFieldState, formState } = useFormContext();

	const fieldState = getFieldState(fieldContext.name, formState);

	if (!fieldContext) {
		throw new Error("useFormField should be used within <FormField>");
	}

	const { id } = itemContext;

	return {
		id,
		name: fieldContext.name,
		formItemId: `${id}-form-item`,
		formDescriptionId: `${id}-form-item-description`,
		formMessageId: `${id}-form-item-message`,
		...fieldState,
	};
};

type FormItemContextValue = {
	id: string;
};

const FormItemContext = createContext<FormItemContextValue>({} as FormItemContextValue);

const formItemVariants = cva("py-2", {
	variants: {
		intent: {
			column: "",
			inlined: "xl:grid xl:grid-cols-[16rem_1fr]",
		},
	},
	defaultVariants: {
		intent: "column",
	},
});

const FormItem = forwardRef<
	HTMLDivElement,
	HTMLAttributes<HTMLDivElement> & VariantProps<typeof formItemVariants>
>(({ intent, className, ...props }, ref) => {
	const id = useId();

	return (
		<FormItemContext.Provider value={{ id }}>
			<div ref={ref} className={formItemVariants({ intent, className })} {...props} />
		</FormItemContext.Provider>
	);
});
FormItem.displayName = "FormItem";

const formLabelVariants = cva(undefined, {
	variants: {
		intent: {
			column: "text-md1 font-semibold text-foreground",
			inlined: "inline-flex h-11 items-center py-2 text-md1 font-medium text-foreground",
		},
	},
	defaultVariants: {
		intent: "column",
	},
});

const FormLabel = forwardRef<
	ElementRef<typeof LabelPrimitive.Root>,
	ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & VariantProps<typeof formLabelVariants>
>(({ intent, className, ...props }, ref) => {
	const { formItemId } = useFormField();

	return (
		<Label
			ref={ref}
			className={formLabelVariants({ intent, className })}
			htmlFor={formItemId}
			{...props}
		/>
	);
});
FormLabel.displayName = "FormLabel";

const FormControl = forwardRef<ElementRef<typeof Slot>, ComponentPropsWithoutRef<typeof Slot>>(
	({ ...props }, ref) => {
		const { error, formItemId, formDescriptionId, formMessageId } = useFormField();

		return (
			<Slot
				ref={ref}
				id={formItemId}
				aria-describedby={
					!error ? `${formDescriptionId}` : `${formDescriptionId} ${formMessageId}`
				}
				aria-invalid={!!error}
				{...props}
			/>
		);
	},
);
FormControl.displayName = "FormControl";

const FormDescription = forwardRef<HTMLParagraphElement, HTMLAttributes<HTMLParagraphElement>>(
	({ className, ...props }, ref) => {
		const { formDescriptionId } = useFormField();

		return (
			<p
				ref={ref}
				id={formDescriptionId}
				className={cn("text-sm text-muted-foreground", className)}
				{...props}
			/>
		);
	},
);
FormDescription.displayName = "FormDescription";

const formMessageVariants = cva("text-xs text-destructive", {
	variants: {
		intent: {
			column: "",
			inlined: "col-start-2 mt-2",
		},
	},
	defaultVariants: {
		intent: "column",
	},
});

const FormMessage = forwardRef<
	HTMLParagraphElement,
	HTMLAttributes<HTMLParagraphElement> & VariantProps<typeof formMessageVariants>
>(({ intent, className, children, ...props }, ref) => {
	const { error, formMessageId } = useFormField();

	if (error === undefined) return null;

	return (
		<p
			ref={ref}
			id={formMessageId}
			className={formMessageVariants({ intent, className })}
			{...props}
		>
			{error.message}
		</p>
	);
});
FormMessage.displayName = "FormMessage";

export { useFormField, FormItem, FormLabel, FormControl, FormDescription, FormMessage, FormField };
export { FormProvider as Form } from "react-hook-form";

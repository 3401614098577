import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useFormContext } from "react-hook-form";

type TextInputProps = {
	name: string;
	label?: string;
	placeholder?: string;
	value?: string;
	disabled?: boolean;
	readOnly?: boolean;
	onChange?: (value: string) => void;
	shouldUnregister?: boolean;
	intent?: "column" | "inlined";
};

export default function TextInput({
	name,
	label,
	placeholder,
	value,
	disabled,
	readOnly,
	onChange,
	shouldUnregister,
	intent,
}: TextInputProps) {
	const { control } = useFormContext();

	return (
		<FormField
			defaultValue={value}
			control={control}
			name={name}
			shouldUnregister={shouldUnregister}
			render={({ field }) => (
				<FormItem intent={intent}>
					{label && <FormLabel intent={intent}>{label}</FormLabel>}

					<FormControl>
						<Input
							className="h-11 border-secondary/20"
							disabled={disabled}
							readOnly={readOnly}
							placeholder={placeholder}
							{...field}
							onChange={(e) => {
								field.onChange(e);
								onChange?.call(undefined, e.target.value);
							}}
						/>
					</FormControl>

					<FormMessage intent={intent} />
				</FormItem>
			)}
		/>
	);
}
